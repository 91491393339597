@font-face {
  font-family: 'CircularStd-Book';
  src: url('/static/fonts/CircularStd-Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd-Medium';
  src: url('/static/fonts/CircularStd-Medium.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CircularStd-Bold';
  src: url('/static/fonts/CircularStd-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

label + .MuiInput-formControl {
  margin-top: 13px !important;
}